<template>
  <div class="search">
    <div class="search-input">
      <span>用户账号：</span>
      <el-input
        v-model="username"
        @change="handleInput"
        placeholder="请输入用户名"
      ></el-input>
    </div>
  </div>
</template>

<script>
export default {
  name: "Search",
  props: {
    handleInput: Function,
  },
  data() {
    return {
      username: "",
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
/deep/.el-input {
  width: 78% !important;
}
.search {
  margin-bottom: 20px;
  &-input {
    width: 20%;
  }
}
</style>
